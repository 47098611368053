<template>
  <div class="login">
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-6-tablet is-5-desktop is-4-widescreen">
              <!-- https://buefy.org/extensions/veevalidate/ -->
              <!-- https://codesandbox.io/s/nnqnonwn9p?from-embed -->
              <form  @submit.prevent="handleSubmit">
                <div class="content">
                  <h3 class="has-text-centered">Sign up For Absolutely Free</h3>
                </div>
                <b-field label="Organization Name"
                         label-for="workspaceTitle"
                         :type="{'is-danger': errors.has('workspaceTitle')}"
                         :message="errors.first('workspaceTitle')"
                         >
                  <b-input
                           v-model="workspaceTitle"
                           placeholder="Must contain letters only"
                           size="is-large"
                           id="workspaceTitle"
                           name="workspaceTitle"
                           v-validate="'required'">
                  </b-input>
                </b-field>
                <b-field label="Full Name"
                         label-for="fullName"
                         :type="{'is-danger': errors.has('fullName')}"
                         :message="errors.first('fullName')"
                         >
                  <b-input
                           v-model="fullName"
                           placeholder="Must contain letters only"
                           size="is-large"
                           id="fullName"
                           name="fullName"
                           v-validate="'required'">
                  </b-input>
                </b-field>
                <b-field label="Email"
                         label-for="email"
                         :type="{'is-danger': errors.has('email')}"
                         :message="errors.first('email')">
                  <b-input placeholder="e.g. name@email.com"
                           id="email"
                           type="email"
                           icon="envelope"
                           size="is-large"
                           v-model="email"
                           name="email"
                           v-validate="'required|email'">
                  </b-input>
                </b-field>
                <b-field label="Password"
                         label-for="password"
                         :type="{'is-danger': errors.has('password')}"
                         :message="errors.first('password')">
                  <b-input placeholder="*******"
                           id="password"
                           type="password"
                           icon="lock"
                           size="is-large"
                           password-reveal
                           v-model="password"
                           name="password"
                           v-validate="'required|min:5'">
                  </b-input>
                </b-field>
                <b-field label="Confirm Password"
                         label-for="confirm-password"
                         :type="{'is-danger': errors.has('confirm-password')}"
                         :message="[{
                         'The confirm password field is required':
                         errors.firstByRule('confirm-password', 'required'),
                         'The confirm password is not valid':
                         errors.firstByRule('confirm-password', 'is')
                    }]">
                  <b-input placeholder="*******"
                           type="password"
                           id="confirm-password"
                           size="is-large"
                           icon="lock"
                           password-reveal
                           v-model="confirmPassword"
                           name="confirm-password"
                           v-validate="{ required: true, is: password }"/>
                </b-field>
                <b-button rounded class="button is-primary is-large is-fullwidth"
                          native-type="submit"
                          :loading="submitBtnLoading">
                  Create Account
                </b-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="third-section-background-img">
      <div class="column">
        <div class="homepage-third-section">
          <div>
            <img
              src="../assets/twork.png"
              alt="TWORK company logo"
            >
          </div>
          <h2 class="has-text-white is-size-2">
            The #1 platform for project management
          </h2>
        </div>
      </div>
      <div class="homepage-footer has-text-white">
        <div>
          © 2023 Twork by Qian Pan
        </div>
        <div>
          Terms & Privacy
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CONSTANTS as REGISTER_CONSTANTS } from '@/store/modules/register/register.constants';

export default {
  name: 'SignupView',
  data() {
    return {
      fullName: null,
      email: null,
      workspaceTitle: null,
      password: null,
      confirmPassword: null,
      submitBtnLoading: false,
    };
  },
  methods: {
    /* TODO: these async functions needs try/catch
    or have an error handler utility wrapper function */
    async handleSubmit() {
      this.submitBtnLoading = true;
      if (await this.isFormValid()) {
        this.register();
      } else {
        this.submitBtnLoading = false;
        this.$buefy.toast.open({
          message: 'Form is not valid! Please check the fields.',
          duration: 3000,
          type: 'is-danger',
          position: 'is-bottom'
        });
      }
    },
    async isFormValid() {
      const isValid = await this.$validator.validateAll();
      return isValid;
    },
    async register() {
      try {
        const {
          email, password, workspaceTitle, fullName
        } = this;
        await this.$store.dispatch(REGISTER_CONSTANTS.ACTIONS.REGISTER_REQUEST, {
          userDTO: { email, password, fullName },
          workspaceDTO: { title: workspaceTitle }
        });
        this.$router.push({ name: 'login' });
      } catch (error) {
        this.submitBtnLoading = false;
        this.$buefy.toast.open({
          message: error.response.data,
          duration: 3000,
          type: 'is-danger',
          position: 'is-bottom'
        });
        console.error(error);
      }
    },
  }
};
</script>
